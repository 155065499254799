export { matchers } from './client-matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20')
];

export const dictionary = {
	"": [2],
	"about": [3],
	"admin": [~4],
	"admin/compile": [~5],
	"admin/delete": [~6],
	"admin/login": [7],
	"admin/s3list": [~8],
	"admin/uploadfolder": [~9],
	"test/button": [11],
	"test/fa": [12],
	"test/icons": [13],
	"test/lazy": [14],
	"test/pop": [15],
	"test/pop2": [16],
	"test/pop3": [17],
	"test/popperjs": [18],
	"test/portrait": [19],
	"test/slug/[id]": [20],
	"content/[id]": [10]
};